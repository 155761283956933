#auth-page-container {
    flex: 1;
    display: flex;
    height: 100%;

    /*.image {
        flex: 0 1 100%;
        box-shadow: inset -15px 1px 29px 0 rgba(27, 53, 128, 0.77);
        background-image: linear-gradient(222deg, var(--cobalt), #001768);
        z-index: 0;

        img {
            height: 600px;
            display: block;
            margin: auto;
        }
    }*/

    .welcome-container {
        display: flex;
        flex: 1 0 50%;
        background: #001768;
        justify-content: center;
        color: #dddddd;
        z-index: 1;
        position: relative;
        flex-direction: column;
        
        .welcome {
            margin: auto;
        }

        .logoImage {
            img {
                height: 50px;
            }    
        }

        .title {
            font-family: Poppins, Montserrat;
            font-size: 44px;
            font-weight: 600;
            margin-bottom: 50px;
            color: white;
        }

        .subtitle {
            font-family: Poppins, Montserrat;
            font-size: 30px;
            font-weight: 300;
            margin-bottom: 30px;
        }

        .featuresList {
            font-family: Poppins, Montserrat;
            font-size: 20px;
            font-weight: 300;
            line-height: 30px;
            margin-bottom: 100px;
        }

        .LPLogo {
            img {
                height: 50px;
            }    
        }
    }

    .auth-form-container {
        display: flex;
        flex: 1 0 50%;
        background: white;
        justify-content: center;
        position: relative;
        z-index: 1;
        margin: auto;
    }
}

/* Portrait and Landscape */
@media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (-webkit-min-device-pixel-ratio: 1){
        #auth-page-container {
            .image {
                flex-basis: 0%;
                opacity: .4;
            }
        }
}
