#upload-form-container {
    background-color: var(--cobalt);
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;

    .main-container {
        background-color: #ffffff;
        max-width: 900px;
        width: 55%;
        height: 527px;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

/* Portrait and Landscape */
@media screen 
    and (max-device-width: 550px) {
        .main-container {
            padding: 0px
        }
        h1 {
            font-size: 32px;
        }
    
}
