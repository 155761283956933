.toast-wrapper {
    display: flex;
}

.toast-icon {
    margin-right: 8px;
}
#auth-page-container {
    flex: 1 1;
    display: flex;
    height: 100%;
}

    /*.image {
        flex: 0 1 100%;
        box-shadow: inset -15px 1px 29px 0 rgba(27, 53, 128, 0.77);
        background-image: linear-gradient(222deg, var(--cobalt), #001768);
        z-index: 0;

        img {
            height: 600px;
            display: block;
            margin: auto;
        }
    }*/

    #auth-page-container .welcome-container {
        display: flex;
        flex: 1 0 50%;
        background: #001768;
        justify-content: center;
        color: #dddddd;
        z-index: 1;
        position: relative;
        flex-direction: column;
    }

    #auth-page-container .welcome-container .welcome {
            margin: auto;
        }

    #auth-page-container .welcome-container .logoImage img {
                height: 50px;
            }

    #auth-page-container .welcome-container .title {
            font-family: Poppins, Montserrat;
            font-size: 44px;
            font-weight: 600;
            margin-bottom: 50px;
            color: white;
        }

    #auth-page-container .welcome-container .subtitle {
            font-family: Poppins, Montserrat;
            font-size: 30px;
            font-weight: 300;
            margin-bottom: 30px;
        }

    #auth-page-container .welcome-container .featuresList {
            font-family: Poppins, Montserrat;
            font-size: 20px;
            font-weight: 300;
            line-height: 30px;
            margin-bottom: 100px;
        }

    #auth-page-container .welcome-container .LPLogo img {
                height: 50px;
            }

    #auth-page-container .auth-form-container {
        display: flex;
        flex: 1 0 50%;
        background: white;
        justify-content: center;
        position: relative;
        z-index: 1;
        margin: auto;
    }

/* Portrait and Landscape */
@media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (-webkit-min-device-pixel-ratio: 1){
            #auth-page-container .image {
                flex-basis: 0%;
                opacity: .4;
            }
}

.labeled-input{
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    position: relative;
}

    .labeled-input .app-icon {
        position: absolute;
        left: 17px;
        bottom: 8px;
    }

    .labeled-input .labels-container {
        display: flex;
        justify-content: space-between;
    }

    .labeled-input .labels-container .label {
            height: 16px;
            font-family: Poppins, Montserrat;
            font-size: 12px;
            line-height: 1.33;
            color: var(--black);
            margin-bottom: 11px;
            padding-left: 8px;
        }

    .labeled-input .labels-container .label-required {
            color: #bb4a4a;
            margin-left: 5px;
        }

    .labeled-input .labels-container .error {
            color: #bb4a4a;
            font-size: 12px;
        }

    .labeled-input .input {
        height: 40px;
        border-radius: 4px;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px var(--white);
        background-color: #ffffff;
        padding: 0px 45px;

        font-size: 12px;
        font-weight: 300;
        line-height: 1.33;
        color: var(--brown-grey);
    }

    .labeled-input .input.error {
            border-color: #bb4a4a;
        }

    .labeled-input .rounded {
        border-radius: 20px;
    }
/* .app-icon {
    svg {
        width: 100%;
        height: 100%;
    }
} */

.button {
    font-size: 18px;
    color: #ffffff;
    border-radius: 30px;
    transition: opacity .3s ease;
}

    .button.secondary {
        width: 82px;
        height: 36px;
        border-radius: 18px;
        border: solid 1px var(--black);
        font-size: 12px;
        font-weight: 300;
        text-align: center;
        color: var(--black);
        background-color: transparent;
    }

    .button.primary {
        width: 169px;
        height: 44px;
        box-shadow: 0 4px 10px 0 rgba(35, 64, 143, 0.44);
        background-color: var(--cobalt);
    }

    .button[disabled] {
        background-color: var(--very-light-pink);
        box-shadow: none;
    }

    .button:hover {
        opacity: .8;
    }
#sign-in-form-container{
    width: 500px;
    flex-direction: column;
}
    /*padding-top: 156px;*/
    #sign-in-form-container .title {
        margin: 0 auto;
        font-family: Poppins, Montserrat;
        font-size: 44px;
        font-weight: 600;
        color: var(--cobalt);
        margin-bottom: 15px;
    }
    #sign-in-form-container .forget-password-label{
        line-height: 16px;
        font-size: 12px;
        line-height: 1.33;
        color: var(--brown-grey);
        display: flex;
        justify-content: flex-end;
        margin: 12px 0px 44px 0px;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        cursor: pointer;
    }
    #sign-in-form-container .bot-container {
        display: flex;
        flex-direction: column;
        align-items: left
    }
    #sign-in-form-container .or-label{
        margin: 20px 0px 18px;
    }
    #sign-in-form-container .link {
        line-height: 21px;
        font-size: 18px;
        color: var(--cobalt);
    }
    #sign-in-form-container .sign-in[disabled] {
            background-color: var(--very-light-pink);
            box-shadow: none;
        }

#sign-in-form-container{
    width: 500px;
    align-items: left;
    flex-direction: column;
}
    /*padding-top: 156px;*/
    #sign-in-form-container .title {
        margin: 0 auto;
        font-family: Poppins, Montserrat;
        font-size: 44px;
        font-weight: 600;
        text-align: left;
        color: var(--cobalt);
        margin-bottom: 30px;
    }
    #sign-in-form-container .subtitle {
        margin: 0 auto;
        font-family: Poppins, Montserrat;
        font-size: 20px;
        font-weight: 400;
        text-align: left;
        color: var(--cobalt);
        margin-bottom: 30px;
    }
    #sign-in-form-container .forget-password-label{
        line-height: 16px;
        font-size: 12px;
        line-height: 1.33;
        color: var(--brown-grey);
        display: flex;
        justify-content: flex-end;
        margin: 12px 0px 44px 0px;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        cursor: pointer;
    }
    #sign-in-form-container .bot-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 2rem;
    }
    #sign-in-form-container .or-label{
        margin: 20px 0px 18px;
    }
    #sign-in-form-container .link {
        line-height: 21px;
        font-size: 18px;
        text-align: center;
        color: var(--cobalt);
    }
    #sign-in-form-container .sign-in[disabled] {
            background-color: var(--very-light-pink);
            box-shadow: none;
        }

#dashboard-page-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

    #dashboard-page-container #app-header {
        background-color: var(--white);
    }

    #dashboard-page-container .top-view-container {
        display: flex;
        position: relative;
        flex: 1 0 auto;
    }

    #dashboard-page-container .header-filters-container {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
    }

    #dashboard-page-container .main-view-container {
        display: flex;
        flex: 0 1 100%;
        overflow: hidden;
    }

#app-header{
    height: 65px;
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
    color: #fff;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

    #app-header .header-block.user .show-arrow_icon {
            display: flex;
            transition-duration: 256ms;
            transform-origin: center center;
            width: 8px;
            height: 8px;
            margin-left: 10px;

        }

    #app-header .header-block.user .show-arrow_icon.expanded {
                transform: rotate(180deg);
            }

    #app-header .header-block.user .show-arrow_icon svg {
                width: 100%;
                height: 100%;

                fill: var(--white);
                stroke: var(--white);
            }

    #app-header.dark .header-block.user {
            color: var(--black);
        }

    #app-header.dark .header-block.user .app-icon svg path {
                    fill: var(--black);
                }

    #app-header.dark .header-block.user .show-arrow_icon svg {
                    fill: var(--black);
                    stroke: var(--black);
                }

    #app-header.dark .header-block.company {
            color: var(--black);
        }

    #app-header.dark .header-block.company .app-icon svg path {
                    fill: var(--black);
                }

    #app-header.dark .header-block.date {
            color: var(--black);
        }

    #app-header.dark .header-block.date .app-icon svg path {
                    fill: var(--black);
                }

    #app-header.dark .separator {
            background-color: var(--very-light-pink);
        }

    #app-header:not(.dark) button.secondary {
            color: #fff;
            border-color: #fff;
        }

    #app-header .user-section {
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 16px;
        font-size: 12px;
        font-weight: 300;
        text-align: center;
    }

    #app-header .header-block {
        display: flex;
        flex: 1 0 auto;
        justify-content: center;
        align-items: center;
        padding: 0px 1em;
        height: 100%;

    }

    #app-header .app-icon {
        margin-right: 10px;
    }

    #app-header .separator {
        width: 1px;
        height: 35px;
        background-color: #ffffff;
        margin: 0 10px;
    }


.base-drop-down-container {
    position: relative;
    margin: 5px;
    cursor: pointer;
}

    .base-drop-down-container .app-icon.icon-more {
        transition: background-color .3s ease;
        width: 32px;
        height: 32px;
        border-radius: 3px;
    }

    .base-drop-down-container:hover .app-icon.icon-more {
            background-color: #eaeaea;
        }

    .base-drop-down-container.expanded .app-icon.icon-more {
            background-color: var(--black);
        }

    .base-drop-down-container.expanded .app-icon.icon-more svg circle {
                fill: var(--white);
            }

    .base-drop-down-container .list-item {
        height: 45px;
        padding: 0px 16px;
        align-items: center;
        display: flex;
        font-size: 14px;
        color: black;
    }

    .base-drop-down-container .list-item:hover {
            background-color: var(--pale-lilac);
        }

    .base-drop-down-container a {
        text-decoration: none;
    }

    .base-drop-down-container .base-drop-down-selections-container {
        z-index: 999;
        position: absolute;

        right: 0px;
        width: 200px;
        border-radius: 4px;
        box-shadow: -1px 1px 10px 0 rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
        cursor: pointer;
    }

    .base-drop-down-container .base-drop-down-selections-container .selection-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 3px 16px;
        }

    .base-drop-down-container .base-drop-down-selections-container .selection-item:hover {
                background-color: var(--white);;
            }
.scenarios-panel {
    min-width: 245px;
    max-width: 245px;
    background-color: var(--white);
    z-index: 1;
    padding: 10px 12px 10px 23px;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

    .scenarios-panel .label {
        font-size: 14px;
        letter-spacing: 0.7px;
        color: var(--black);
        padding: 23px 0px;
    }

    .scenarios-panel .routes {
        display: flex;
        flex-direction: column;
    }

    .scenarios-panel .link {
        line-height: 45px;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: -0.4px;
        color: var(--brown-grey-light);
        text-decoration: none;
        border-bottom: 1px solid var(--very-light-pink);
        transition: color .3s ease;
        cursor: pointer;
    }

    .scenarios-panel .link.active, .scenarios-panel .link:hover {
            color: var(--black);
        }

    .scenarios-panel .link.active {
            border-bottom: none;
        }

    .scenarios-panel .show-metric-bar-button {
        font-size: 14px;
        margin: 40px 0px 12px 0px;
        cursor: pointer;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        display: flex;
        align-items: center;
    }

    .scenarios-panel .show-metric-bar-button .show-metric-bar-button_icon {
            display: flex;
            transition-duration: 256ms;
            transform-origin: center center;
            width: 8px;
            height: 8px;
            margin-left: 10px;

        }

    .scenarios-panel .show-metric-bar-button .show-metric-bar-button_icon.expanded {
                transform: rotate(180deg);
            }

    .scenarios-panel .show-metric-bar-button .show-metric-bar-button_icon svg {
                width: 100%;
                height: 100%;
            }

    .scenarios-panel .metric-bars-pane_container .metric-bar_container {
            height: auto;
            align-items: flex-start;
            flex-direction: column;
            position: relative;
            padding: 12px 18px 4px 18px;
            margin-bottom: 12px;
            border-radius: 8px;
        }

    .scenarios-panel .metric-bars-pane_container .metric-bar_container .metric-bar_label {
                margin-bottom: 12px;
            }

    .scenarios-panel .metric-bars-pane_container .metric-bar_container .metric-bar-item {
                min-height: 20px;
                margin: 0px 0px 8px 0px;
            }

    .scenarios-panel .metric-bars-pane_container .metric-bar_container .metric-bar_close-button {
                display: none;
            }
.toggler-tub {
    width: 32px;
    height: 18px;
    background-color: var(--very-light-pink);
    border-radius: 12px;
    padding: 2px;
    cursor: pointer;
    transition: background-color 256ms;
}

    .toggler-tub .head {
        width: 14px;
        height: 14px;
        background-color: #ffffff;
        border-radius: 50%;
        transition: transform 256ms;
    }

    .toggler-tub.active {
        background-color: var(--cobalt);
    }

    .toggler-tub.active .head {
            transform: translateX(14px);
        }
.scenario-item .select-all-section {
        display: flex;
        width: 100%;
        font-size: 12px;
        letter-spacing: -0.34px;
        line-height: 1.3;
        margin: 15px 0px;
        color: var(--black);
        cursor: pointer;
    }

        .scenario-item .select-all-section .toggler-spacer{
            padding: 0px 11px 0px 15px;
        }
    .scenario-item .sub-link-block {
        display: flex;
        flex-direction: column;
    }
    .scenario-item .sub-link-block .link {
            font-size: 12px;
            padding-left: 1rem;
            line-height: 3;
            letter-spacing: -0.34px;
            color: var(--black);
            border-bottom: none;
            white-space: nowrap;
        }
    .scenario-item .sub-link-block .link.active {
                background-color: var(--cobalt);
                border-radius: 3.5px;
                color: white;
                position: relative;
            }
    .scenario-item .sub-link-block .link.active:before {
                    content: '';
                    width: 6px;
                    height: 100%;
                    border-radius: 3.5px;
                    background-color: #152b68;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
.formatted-text-container ul{
        list-style: disc;
        /*margin-left: 2rem;*/
    }

.metric-bars-pane_container{
    display: flex;
    flex-direction: column;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

    .metric-bars-pane_container .metric-bars-pane_label {
        color: #3B3B3B;
        font-size: 12px;
        flex: 1 0 auto;
        margin-right: 19px;
    }


.metric-bar_container{
    height: 44px;
    display: flex;
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 5px 0 rgba(194,196,197,0.5);
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    padding: 12px;
    overflow: hidden;
    overflow-x: auto;
    align-items: center;
}

    .metric-bar_container .metric-bar_label {
        color: #3B3B3B;
        font-size: 12px;
        flex: 1 0 auto;
        margin-right: 19px;
    }

    .metric-bar_container .metric-bar_close-button.app-icon {
            margin: 8px;
            cursor: pointer;
        }

    .metric-bar_container .metric-bar_close-button.app-icon svg {
                width: 8px;
                height: 8px;
            }


.metric-bar-item{
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    margin-right: 12px;
}

    .metric-bar-item .metric-bar-item_icon-container {
        height: 16px;
        width: 16px;
        min-height: 16px;
        min-width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
    }

    .metric-bar-item .metric-bar-item_icon-container .metric-bar-item_icon-inner-item {
            height: 6px;
            width: 6px;
            border-radius: 50%;
        }

    .metric-bar-item .metric-bar-item_label {
        color: #3B3B3B;
        font-size: 12px;
        font-weight: 300;
    }
#top-filters-panel {
    display: flex;
    height: 60px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
    background-color: #ffffff;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}

    #top-filters-panel .filters-list {
        display: flex;
        flex: 1 1 100%;
    }

    #top-filters-panel .manage-buttons-container {
        display: flex;
        justify-self: flex-end;
        margin: 5px;
    }

    #top-filters-panel .manage-button-container {
        min-width: 60px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    #top-filters-panel .manage-button-container:hover {
            background-color: var(--pale-lilac);
        }

    #top-filters-panel .manage-button-container .label {
            line-height: 14px;
            font-size: 12px;
            font-weight: 300;
            text-align: center;
            color: var(--black);
            cursor: pointer;
            -webkit-user-select: none;
               -moz-user-select: none;
                -ms-user-select: none;
                    user-select: none;
        }

    #top-filters-panel .manage-button-container .app-icon {
            width: 18px;
            height: 20px;
        }

    #top-filters-panel .base-drop-down-selections-container {
        min-width: 220px;
    }
.filter-container {
    height: 60px;
    min-width: 90px;
    max-width: 180px;
    flex: 1 1;
    background-color: #ffffff;
    border-right: 1px solid var(--very-light-pink);
    padding: 5px 0px;
    position: relative;
}

    .filter-container.location {
        border-left: 1px solid var(--very-light-pink);
        margin-left: 10px;
        position: relative;
    }

    .filter-container.location:before {
            content: '';
            background-color: var(--white);
            width: 10px;
            position: absolute;
            top: 0;
            left: -11px;
            height: 100%;
        }

    .filter-container .highlighter {
        height: 3px;
        width: 100%;
        background-color: var(--cobalt);
        position: absolute;
        top: 0;
        left: 0;
    }

    .filter-container .selected-value {
        display: flex;
        align-items: center;
        height: 30px;
        line-height: 15px;
        font-size: 12px;
        font-weight: 300;
        padding: 0px 16px;
        color: var(--black);
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

    .filter-container .selected-value.default {
            color: var(--brown-grey);
        }

    .filter-container .drop-down-container .list-item {
            height: 31px;
            line-height: 31px;
            font-size: 12px;
            font-weight: 300;
            padding: 0px 16px;
            color: var(--brown-grey);
            line-height: 12px;
            display: flex;
            align-items: center;
        }

    .filter-container .drop-down-container .list-item:hover {
                background-color: var(--white);
            }
.drop-down-container .label-container {
        display: flex;
        justify-content: space-between;
        padding: 0px 13px 0 16px;
        cursor: pointer;
    }

        .drop-down-container .label-container .label {
            line-height: 24px;
            font-size: 12px;
            color: var(--black);
        }

    .drop-down-container .icon {
        width: 9px;
        height: 5px;
    }

    .drop-down-container .list-container {
        z-index: 999;
        position: absolute;
        top: 29px;
        width: 100%;
        padding: 0px 0px 8px 0px;
        border-radius: 4px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
    }

    .drop-down-container .list-container .list-item {
            cursor: pointer;
        }
.level-three-label_container {
    display: flex;
    margin-right: 5px;
    /*text-transform: uppercase;*/
    font-size: 12px;
    line-height: 1.6;
    /* white-space: nowrap; */
    margin-bottom: 4px;
    cursor: pointer;
    color: #222;
    flex: 1 0 100%;
    justify-content: space-between;
}

    .level-three-label_container.one-per-line{
        flex: 1 0 100%;
    }

    .level-three-label_container .level-three-label_name {
        padding: 0px 4px;
        /*line-height: 2;*/
        background-color: #f5f5f5;
    }

    .level-three-label_container .level-three-label_value {
        padding: 2px 4px;
        border-radius: 2px;
        margin-left: 4px;
        font-weight: 500;
    }
.metric-state-label{
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    margin-right: 12px;
}

    .metric-state-label .metric-state-label_icon-container {
        height: 16px;
        width: 16px;
        min-height: 16px;
        min-width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
    }

    .metric-state-label .metric-state-label_icon-container .metric-state-label_icon-inner-item {
            height: 6px;
            width: 6px;
            border-radius: 50%;
        }

    .metric-state-label .metric-state-label_label {
        color: #3B3B3B;
        font-size: 12px;
        font-weight: 300;
    }
.system-labels {
    display: flex;
    flex-wrap: wrap;
}

    .system-labels .more-button {
        font-size: 8px;
        font-weight: 300;
        color: var(--black);
        text-decoration: underline;
        margin-top: 5px;
        margin-left: 5px;
        cursor: pointer;
    }
.level-two-capability {
    width: 100%;
    box-shadow: 0 2px 5px 0 rgba(158, 158, 158, 0.53);
    background-color: #ffffff;
    padding: 18px 12px;
    margin-bottom: 6px;
    cursor: pointer;
    position: relative;
    transition: background-color .3s ease;
    white-space: break-spaces;
    border-radius: 8px;
}

    .level-two-capability h3 {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.4px;
        color: var(--black);
        margin-bottom: 12px;
        line-height: 16px;
        padding-bottom: 8px;
        border-bottom: 1px solid #ddd;
    }

    .level-two-capability p {
        font-size: 12px;
        font-weight: 400;
        padding-top: 10px;
    }

    .level-two-capability.active-in-scenario {
        padding-top: 24px;
    }

    .level-two-capability.active-in-scenario:before {
            content: '';
            position: absolute;
            width: 29px;
            height: 8px;
            border-radius: 2px;
            background-color: var(--cobalt);
            left: 13px;
            top: 9px;
        }

    .level-two-capability:active {
        box-shadow: none;
        background-color: var(--white);
    }
.level-one-entity {
    background-color: var(--white);
    padding: 12px 8px 4px 8px;
    width: 312px;
    border-radius: 10px;
}

    .level-one-entity.core {
        background-color: rgba(200, 207, 227, 0.5);
    }

    .level-one-entity .level-one-header {
        width: 100%;
        box-shadow: 0 2px 5px 0 rgba(158, 158, 158, 0.53);
        background-color: #ffffff;
        padding: 14px;
        margin-bottom: 20px;
        cursor: pointer;
        border-radius: 8px;
    }

    .level-one-entity .level-one-header h2 {
            /*text-transform: uppercase;*/
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.7px;
            color: var(--black);
            line-height: 20px;
        }

    .level-one-entity .level-one-header:active {
            box-shadow: none;
            background-color: var(--white);
        }
#capabilities-dashboard {
    flex: 0 1 100%;
    padding: 27px 23px;
    /* display: grid;
    grid-template-columns: repeat(auto-fill,minmax(312px,1fr));
    grid-column-gap: 13px;
    grid-row-gap: 16px; */
    align-items: start;
    max-height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

}

    #capabilities-dashboard .grid-section-container {
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(312px,1fr));
        grid-column-gap: 13px;
        grid-row-gap: 16px;
        justify-items: center;
        align-items: start;
        margin-bottom: 14px;
    }

    #capabilities-dashboard .grid-label {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 14px;
    }

    #capabilities-dashboard hr {
        margin: 2rem 0px;
        border-top: 1px solid #d0d0d0;
    }
#upload-form-container {
    background-color: var(--cobalt);
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
}

    #upload-form-container .main-container {
        background-color: #ffffff;
        max-width: 900px;
        width: 55%;
        height: 527px;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

/* Portrait and Landscape */
@media screen 
    and (max-device-width: 550px) {
        .main-container {
            padding: 0px
        }
        h1 {
            font-size: 32px;
        }
    
}

#admin-page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

    #admin-page .admin-page_main {
        padding-left: 295px;
        padding-right: 60px;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        overflow: hidden;
    }

    #admin-page .admin-page_title {
        display: flex;
        justify-content: space-between;
        flex: 0 0 auto;
    }

    #admin-page .admin-page_title span {
            font-size: 24px;
            color: #3b3b3b;
            font-weight: 600;
            margin-bottom: 37px;
        }

    #admin-page #app-header {
        background-color: var(--white);
    }

    #admin-page .top-view-container {
        display: flex;
        position: relative;
        /* flex: 1 0 125px; */
        min-height: 125px;
    }

    #admin-page .top-view-container .logo-background {
            min-width: 245px;
            height: 125px;
            background-color: var(--cobalt);
        }

    #admin-page .top-view-container .logo-background .company-logo {
                position: relative;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

    #admin-page .invite-buttons button {
            margin: 0 5px;
        }

    #admin-page .admin-page_tabs {
        border-bottom: 2px solid #e4e4e4;
        display: flex;
        flex: 0 0 auto;
        margin-bottom: 36px;
    }

    #admin-page .admin-page_tab {
        font-size: 14px;
        font-weight: 500;
        color: var(--brown-grey);
        padding: 9px 12px;
        cursor: pointer;
        transition: color .3s ease;
    }

    #admin-page .admin-page_tab:hover, #admin-page .admin-page_tab.active {
            color: var(--cobalt);
            font-weight: 600;
            border-bottom: 2px solid var(--cobalt);
        }

    #admin-page .header-filters-container {
        display: flex;
        flex: 1 1;
        flex-direction: column;
    }

    #admin-page .main-view-container {
        display: flex;
        flex: 0 1 100%;
        overflow: hidden;
    }

.user-list-container {
    flex: 0 1 auto;
    overflow: auto;
}
    
    .user-list-container .user-list {
        max-width: 1100px;
        display: grid;
        grid-template-columns: 70px auto 150px 150px 180px auto 100px;
        grid-template-rows: 30px;
        grid-auto-rows: 80px;
    }
    
    .user-list-container .user-list > div {
            background-color: #fff;
            display: flex;
            justify-content: center;
            flex-direction: column;
            border-bottom: 1px #e4e4e4 solid;
        }
    
    .user-list-container .user-list-filter {
        margin-bottom: 36px;
    }
    
    .user-list-container .user-list-filter input {
            width: 338px;
            height: 40px;
            border-radius: 5px;
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
            border: solid 1px #e4e4e4;
            background-color: #ffffff;
            padding: 11px 12px;
            font-size: 14px;
            font-weight: 300;
            color: var(--brown-grey);
        }

/* Portrait and Landscape */
@media only screen 
    and (max-width: 1699px) {
            .user-list-container .user-list {
                display: none;
            }
            .user-list-container .user-list-compact {
                display: block;
            }

                .user-list-container .user-list-compact .header {
                    display: flex;
                }

                    .user-list-container .user-list-compact .header >div {
                        flex: 1 0 auto;
                        height: 30px;
                    }
                        .user-list-container .user-list-compact .header .table-header.MODEL, .user-list-container .user-list-compact .header .table-header.ACTION {
                            display: none;
                        }

                .user-list-container .user-list-compact .user-list-row {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 16px 0px;
                    border-bottom: 1px solid #e4e4e4;
                }

                    .user-list-container .user-list-compact .user-list-row >div {
                        padding: 0px 8px;
                    }

                    .user-list-container .user-list-compact .user-list-row .user-list-item_icon, .user-list-container .user-list-compact .user-list-row .user-list-item_status {
                        padding: 8px;
                    }

                    .user-list-container .user-list-compact .user-list-row .user-list-item_status {
                        flex: 1 0 100%;
                    }

                    .user-list-container .user-list-compact .user-list-row .user-list-item_action {
                        flex: 1 0 100%;
                    }
                    .user-list-container .user-list-compact .user-list-row .user-list-item_company, .user-list-container .user-list-compact .user-list-row .user-list-item_group {
                        line-height: 32px;
                    }
}

@media only screen 
    and (min-width: 1700px) {
            .user-list-container .user-list {
                display: grid;
            }
            .user-list-container .user-list-compact {
                display: none;
            }
}
.user-list-item_icon .app-icon {
        height: 100%;
        display: flex;
        align-items: center;
    }
    
        .user-list-item_icon .app-icon svg {
            width: 56px;
            height: 56px;
        }
.user-list-item_name-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 16px 0px 0px;
}
.user-list-item_name-container .user-list-item_name {
        font-size: 14px;
        color: #3b3b3b;
        font-weight: bold;
        margin-bottom: 4px;
    }
.user-list-item_name-container .user-list-item_email {
        font-size: 12px;
        line-height: 16px;
        color: #808880;
    }

.user-list-item_action {
    align-items: flex-start;
    padding-left: 5px;
}

.user-list-item_action .base-drop-down-selections-container {
        width: 130px;
        right: auto;
        right: initial;
        left: 0px;
        padding: 8px 0px;
    }

.user-list-item_action .base-drop-down-selections-container .list-item{
            height: 24px;
            padding: 4px 16px;

        }

.user-list-item_action .base-drop-down-selections-container .list-item:hover{
                background-color: #e4e4e4;
            }

.user-list-item_status-badge {
    width: 92px;
    height: 12px;
    font-size: 10px;
    line-height: 1.2;
    text-align: center;
    color: #ffffff;
    border-radius: 1px;
}

.user-list-item_status-badge.pending {
        background-color: #fcc300;
    }

.user-list-item_status-badge.completed {
        background-color: #39b97f;
    }

.user-list-item_status-badge.inactive {
        background-color: var(--brown-grey);
    }

.user-list-item_model-container {
    padding-right: 20px;
}

.user-list-item_model-container .user-list-item_model-name-container{
        display: flex;
    }

.user-list-item_model-container .user-list-item_model-name-container .user-list-item_model-name{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            align-self: center;
        }

.user-list-item_model-container .user-list-item_model-name-container .user-list-item_model-delete{ 
            padding-left: 10px;
            cursor: pointer;
        }

.user-list-item_model-container .user-list-item_model-upload {
        background-color: #e4e4e4;
        padding: 11px;
        border-radius: 36px;
        height: 36px;
        cursor: pointer;
        width: 120px;
        color: #ffffff;
        font-size: 12px;
        text-align: center;
        transition: color .3s ease;
    }

.user-list-item_model-container .user-list-item_model-upload:hover {
            color: var(--black)
        }
.user-logo {
    width: 56px;
    height: 56px;
    background-color: var(--cobalt);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-logo_letters {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
}
.confirmation-modal {
    background: white;
    width: 700px;
    margin: auto;
    position: relative;
    padding: 42px 50px;
    text-align: center;
    border-radius: 2px;
    box-shadow: 0 2px 38px 0 rgba(0, 0, 0, 0.28);
}

    .confirmation-modal .close-button {
        position: absolute;
        top: 26px;
        right: 26px;
        width: 14px;
        height: 14px;
        cursor: pointer;
    }

    .confirmation-modal h2 {
        font-size: 44px;
        font-weight: 600;
        color: var(--cobalt);
        margin-bottom: 40px;
    }

    .confirmation-modal p {
        font-size: 18px;
        font-weight: 300;
        line-height: 1.56;
        color: var(--black);
        margin-bottom: 40px;
    }

    .confirmation-modal .confirmation-buttons button {
            margin: 0 13px;
        }

    .confirmation-modal .confirmation-buttons button.secondary {
                background-color: var(--very-light-pink);
                border: none;
                height: 44px;
                width: 169px;
                font-size: 18px;
                color: white;
            }
.table-header {
    font-size: 12px;
    color: var(--black);
    font-weight: 500;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

    .table-header .app-icon {
        display: inline-block;
        width: 12px;
        height: 12px;
    }

    .table-header .app-icon svg {
            width: 100%;
            height: 12px;
        }

.table-header_content {
    display: flex;
}

.table-header_label {
    margin-right: 8px;
}
.licences-list-container {
    flex: 0 1 auto;
    overflow: auto;
}
    
    .licences-list-container .licences-list {
        max-width: 1100px;
        display: grid;
        grid-template-columns: 70px auto 200px 130px 100px 140px 140px 50px;
        grid-template-rows: 30px;
        grid-auto-rows: 80px;
    }
    
    .licences-list-container .licences-list > div {
            border-bottom: 1px #e4e4e4 solid;
        }
    
    .licences-list-container .licences-list .licences-list_header-item{
            font-size: 12px;
            color: #3b3b3b;
        }
    
    .licences-list-container .licences-list > div {
            background-color: #fff;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
.license-item_icon .app-icon {
        height: 100%;
        display: flex;
        align-items: center;
    }
    
        .license-item_icon .app-icon svg {
            width: 56px;
            height: 56px;
        }
.license-item_name-container {
    font-size: 14px;
    font-weight: 600;
    color: var(--black);
}
.license-item_name-container .license-item_name{
        padding: 0px 32px 0px 0px;
    }

.license-item_date {
    color: var(--black);
    font-size: 12px;
    font-weight: 500;
}

.license-item_action {
    align-items: flex-start;
    padding-left: 5px;
}

.license-item_action .base-drop-down-selections-container {
        width: 130px;
        right: auto;
        right: initial;
        left: 0px;
        padding: 8px 0px;
    }

.license-item_action .base-drop-down-selections-container .list-item{
            height: 24px;
            padding: 4px 16px;

        }

.license-item_action .base-drop-down-selections-container .list-item:hover{
                background-color: #e4e4e4;
            }
.companies-list-container {
    flex: 0 1 auto;
    overflow-y: auto;
}
    
    .companies-list-container .companies-list {
        max-width: 1100px;
        display: grid;
        grid-template-columns: 70px auto;
        grid-template-rows: 30px;
        grid-auto-rows: 80px;
    }
    
    .companies-list-container .companies-list > div {
            background-color: #fff;
            display: flex;
            justify-content: center;
            flex-direction: column;
            border-bottom: 1px #e4e4e4 solid;
        }
    
    .companies-list-container .companies-list-filter {
        margin-bottom: 36px;
    }
    
    .companies-list-container .companies-list-filter input {
            width: 338px;
            height: 40px;
            border-radius: 5px;
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
            border: solid 1px #e4e4e4;
            background-color: #ffffff;
            padding: 11px 12px;
            font-size: 14px;
            font-weight: 300;
            color: var(--brown-grey);
        }
.user-list-item_icon .app-icon {
        height: 100%;
        display: flex;
        align-items: center;
    }
    
        .user-list-item_icon .app-icon svg {
            width: 56px;
            height: 56px;
        }
.user-list-item_name-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.user-list-item_name-container .user-list-item_name {
        font-size: 14px;
        color: #3b3b3b;
        font-weight: bold;
        margin-bottom: 4px;
    }
.user-list-item_name-container .user-list-item_email {
        font-size: 12px;
        line-height: 16px;
        color: #808880;
    }

.user-list-item_action {
    align-items: flex-start;
    padding-left: 5px;
}

.user-list-item_action .base-drop-down-selections-container {
        width: 130px;
        right: auto;
        right: initial;
        left: 0px;
        padding: 8px 0px;
    }

.user-list-item_action .base-drop-down-selections-container .list-item{
            height: 24px;
            padding: 4px 16px;

        }

.user-list-item_action .base-drop-down-selections-container .list-item:hover{
                background-color: #e4e4e4;
            }

.user-list-item_status-badge {
    width: 92px;
    height: 12px;
    font-size: 10px;
    line-height: 1.2;
    text-align: center;
    color: #ffffff;
    border-radius: 1px;
}

.user-list-item_status-badge.pending {
        background-color: #fcc300;
    }

.user-list-item_status-badge.completed {
        background-color: #39b97f;
    }

.user-list-item_status-badge.inactive {
        background-color: var(--brown-grey);
    }

.user-list-item_model-container {
    padding-right: 20px;
}

.user-list-item_model-container .user-list-item_model-name-container{
        display: flex;
    }

.user-list-item_model-container .user-list-item_model-name-container .user-list-item_model-name{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            align-self: center;
        }

.user-list-item_model-container .user-list-item_model-name-container .user-list-item_model-delete{ 
            padding-left: 10px;
            cursor: pointer;
        }

.user-list-item_model-container .user-list-item_model-upload {
        background-color: #e4e4e4;
        padding: 11px;
        border-radius: 36px;
        height: 36px;
        cursor: pointer;
        width: 120px;
        color: #ffffff;
        font-size: 12px;
        text-align: center;
        transition: color .3s ease;
    }

.user-list-item_model-container .user-list-item_model-upload:hover {
            color: var(--black)
        }
.modal-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    background-color: #00000075;
    z-index: 99;
    padding-top: 227px;
    padding-bottom: 60px;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.modal-portal {
    position: relative;
    width: 700px;
    height: 100%;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    border-radius: 14px;
    outline: none;
    margin: auto;
}

.modal-portal.upload_modal, .modal-portal.metrics_upload {
        width: 900px;
    }

#modal-container {
    height: 100%;
    min-height: 400px;
    color: var(--black);
}

#modal-container .close-button {
        position: absolute;
        top: 26px;
        right: 26px;
        width: 14px;
        height: 14px;
        cursor: pointer;
    }

#modal-container h2 {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.33;
    }

#modal-container h3 {
        line-height: 32px;
        font-size: 18px;
        font-weight: 400;
        position: relative;
        /*left: 15px;*/
        /*&:before {
            content: '';
            display: block;
            position: absolute;
            background: black;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            left: -15px;
            top: 0;
            bottom: 0;
            margin: auto;
        }*/
    }

#modal-container .section-header {
        display: flex;
    }

#modal-container .header-switcher {
        border-radius: 6px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
        background-color: #f4f4f4;
        padding: 4px 8px;
        margin-left: 2rem;
        font-size: 14px;
        font-weight: 300;
        line-height: 1.5;
        color: var(--black);
        cursor: pointer;
    }

#modal-container .content {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.43;
        margin: 13px 0 22px 0px;
    }

#modal-container .overview-container {
        padding: 36px 36px 0 36px;
    }

#modal-container .overview-container .state-info {
            margin: 15px 0 24px 0;
            padding: 18px 13px;
            background-color: rgba(228, 228, 228, 0.58);
        }

#modal-container .overview-container .state-info .system-name{
                margin-bottom: 8px;
                font-size: 18px;
                font-weight: 600;
                line-height: 1.11;
                color: var(--black);
            }

#modal-container .overview-container .state-info .state-name {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 300;
                line-height: 1.43;
                color: var(--black);
            }

#modal-container .modal-section-container {
        flex: 1 1;
        border-radius: 2px;
        background-color: var(--white);
        padding: 36px;
    }

#modal-container .modal-section-container .sub-title{
            margin-top: 9px;
            margin-bottom: 14px;
            font-size: 16px;
            font-weight: 300;
            line-height: 1.38;
        }

#system-modal {

}

.capability-tree {
    position: relative;
    padding-top: 17px;
}

    .capability-tree.branch {
        padding-left: 20px;
        border-left: 2px solid #717171;
    }

    .capability-tree.branch:before {
            position: absolute;
            top: 35px;
            height: 0px;
            width: 20px;
            border-bottom: 2px solid #2a2a2a;
            content: "";
            display: block;
            left: 0px;
            box-sizing: border-box;
        }

    .capability-tree.branch:last-child {
            border-left: none;
            padding-left: 22px;
        }

    .capability-tree.branch:last-child:before {
                border-left: 2px solid #2a2a2a;
                height: 37px;
                width: 22px;
                top: 0px;
            }

    .capability-tree.branch .collapsable-container-header {
            border-left-color: #717171;
        }

    .capability-tree .label-container {
        display: flex;
        height: 40px;
        border-radius: 4px;
        background-color: #ffffff;
        line-height: 40px;
        font-size: 16px;
        color: var(--black);
        border-left: 4px solid #2a2a2a;
        border-radius: 4px;
        cursor: pointer;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

    .capability-tree .label-container .app-icon {
            width: 23px;
            text-align: right;
        }

    .capability-tree .label-container .app-icon svg {
                width: 8px;
                height: 8px;
            }

    .capability-tree .label-container .app-icon.icon-more {
                display: flex;
                min-width: 40px;
                justify-content: center;
                align-items: center;
            }

    .capability-tree .label-container .label {
            flex: 1 1;
            padding-left: 12px;
            line-height: 40px;
            font-size: 16px;
            color: var(--black);
        }

    .capability-tree .branches {
        padding-left: 22px;
    }

    .capability-tree .base-drop-down-selections-container {
        padding: 0px;
        width: 174px;
        right: 2px;
    }

    .capability-tree .base-drop-down-selections-container .selection-item {
            padding: 0px 0px 0px 14px;
            display: flex;
            height: 45px;
            justify-content: center;
            align-items: center;
        }

    .capability-tree .base-drop-down-selections-container .selection-item .label {
                flex: 0 1 100%;
                padding: 0px;
                font-size: 14px;
            }

    .capability-tree .base-drop-down-selections-container .selection-item .app-icon.arrow-icon {   
                display: flex;
                min-width: 32px;
                min-height: 40px;
                justify-content: center;
                align-items: center;
            }

    .capability-tree .base-drop-down-selections-container .selection-item .app-icon.arrow-icon svg {
                    width: 11px;
                    height: 11px;
                }

.collapsable-container-header {
    display: flex;
    align-items: center;
    min-height: 40px;
    border-radius: 4px;
    background-color: #ffffff;
    line-height: 40px;
    font-size: 16px;
    color: var(--black);
    border-left: 4px solid #2a2a2a;
    border-radius: 4px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

    .collapsable-container-header .app-icon.icon-collapsable {
            width: 23px;
            text-align: right;
        }

    .collapsable-container-header .app-icon.icon-collapsable svg {
                width: 8px;
                height: 8px;
            }

    .collapsable-container-header .app-icon.icon-more {
            display: flex;
            min-width: 32px;
            justify-content: center;
            align-items: center;
            height: 32px;
            margin: 4px;
            border-radius: 4px;
        }

    .collapsable-container-header .app-icon.icon-more svg {
                width: 20px;
            }

    .collapsable-container-header >.label {
        flex: 1 1;
        padding-left: 12px;
        line-height: 20px;
        font-size: 16px;
        color: var(--black);
    }

    .collapsable-container-header .base-drop-down-container.expanded .app-icon.icon-more {
            background-color: var(--black);
            fill: var(--white);
        }

#capability-modal .title {
        font-size: 24px;
        /*text-transform: uppercase;*/
        margin-bottom: 32px;
    }
    #capability-modal h2 {
        font-size: 22px;
    }
#actor-modal .title {
        font-size: 24px;
        /*text-transform: uppercase;*/
        margin-bottom: 32px;
    }
    #actor-modal h2 {
        font-size: 22px;
    }
#contact-support-modal {
    padding: 32px 36px;
}

    #contact-support-modal.success {
        text-align: center;
        padding-top: 45px;
    }

    #contact-support-modal.success .success-message {
            margin-top: 30px;
            margin-bottom: 65px;
        }

    #contact-support-modal h2 {
        color: var(--black);
        font-size: 24px;
        line-height: 28px;
    }

    #contact-support-modal .form-holder {
        margin-top: 26px;
    }

    #contact-support-modal .form-inputs {
        background-color: rgba(228,228,228,0.58);
        padding: 2px 36px 26px 36px;
    }

    #contact-support-modal .form-inputs .label {
            color: var(--black);
            /*text-transform: uppercase;*/
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 11px;
            padding-left: 8px;
        }

    #contact-support-modal .form-inputs input {
            border-radius: 5px;
            max-width: 350px;
        }

    #contact-support-modal .form-inputs .question-wrapper {
            padding-top: 24px;
        }

    #contact-support-modal .form-inputs textarea {
            padding: 12px 15px;
            width: 100%;
            margin-bottom: 26px;
            box-sizing: border-box;
            border: 1px solid var(--white);
            border-radius: 5px;
            background-color: #FFFFFF;
            font-size: 12px;
            line-height: 16px;
            box-shadow: 0 0 3px 0 rgba(0,0,0,0.05);
        }

    #contact-support-modal .form-inputs textarea.error {
                border-color: #bb4a4a;
            }

    #contact-support-modal .form-inputs .label-container {
            display: flex;
            justify-content: space-between;
        }

    #contact-support-modal .form-inputs .question-error {
            color: #bb4a4a;
            font-size: 12px;
        }

    #contact-support-modal .submit-button {
        text-align: center;
        margin-top: 26px;
        margin-bottom: 20px;
    }

    #contact-support-modal .submit-button button {
            width: 230px;
        }
#generic-modal .title {
        font-size: 24px;
        /*text-transform: uppercase;*/
        margin-bottom: 32px;
    }
    #generic-modal h2 {
        font-size: 22px;
    }
#invite-user-modal {
    display: flex;
    flex-direction: column;
    padding: 32px 36px;
}

    #invite-user-modal .invite-user-modal_title {
        font-size: 24px;
        font-weight: 600;
        color: #3b3b3b;
        margin-bottom: 35px;
    }

    #invite-user-modal .invite-user-modal_selectors-section {
        padding: 24px 24px 2px 24px;
        background-color: rgba(228, 228, 228, 0.58);
    }

    #invite-user-modal .invite-user-modal_selectors-section .labeled-input {
            padding: 0 0 24px 0;
        }

    #invite-user-modal .invite-user-modal_selectors-section .labeled-input .label {
                font-size: 12px;
                color: #3b3b3b;
                padding: 0 0 11px 0;
            }

    #invite-user-modal .invite-user-modal_selectors-section .labeled-input .input {
                width: 354px;
                height: 40px;
                border-radius: 5px;
                padding: 0 8px;
                box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
                border: solid 1px #f7f7f7;
                background-color: #ffffff;
            }

    #invite-user-modal .invite-user-modal_selectors-section .invite-user-modal_selector-title{
            font-size: 12px;
            color: #3b3b3b;
            padding-bottom: 11px;
        }

    #invite-user-modal .invite-user-modal_selectors-section .drop-down-container {
            position: relative;
            width: 350px;
            height: 40px;
            background-color: #fff;
            margin-bottom: 24px;
        }

    #invite-user-modal .invite-user-modal_selectors-section .drop-down-container .label-container {
                padding: 12px 9px;
                border-radius: 5px;
            }

    #invite-user-modal .invite-user-modal_selectors-section .drop-down-container .label-container .label{
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 300;
                    color: #808080;
                }

    #invite-user-modal .invite-user-modal_selectors-section .drop-down-container .list-container {
                top: 40px;
                width: 100%;
                padding: 9px 18px;
            }

    #invite-user-modal .invite-user-modal_selectors-section .drop-down-container .list-container .list-item{ 
                    line-height: 20px;
                    margin: 4px 0px;
                    font-size: 12px;
                    font-weight: 300;
                    color: #3b3b3b;
                }

    #invite-user-modal .invite-user-modal_selectors-section .drop-down-container .list-container .list-item:hover{
                        background-color: #eee;
                    }

    #invite-user-modal .invite-user-modal_button {
        text-align: center;
    }

    #invite-user-modal .button {
        margin-top: 26px;
        align-self: center;
    }
#add-licence-modal {
    display: flex;
    flex-direction: column;
    padding: 32px 36px;
}

    #add-licence-modal .add-licence-modal_title {
        font-size: 24px;
        font-weight: 600;
        color: #3b3b3b;
        margin-bottom: 35px;
    }

    #add-licence-modal .add-licence-modal_selectors-section {
        padding: 24px;
        background-color: rgba(228, 228, 228, 0.58);
    }

    #add-licence-modal .add-licence-modal_selectors-section .labeled-input {
            padding: 0 0 24px 0;
        }

    #add-licence-modal .add-licence-modal_selectors-section .labeled-input .label {
                font-size: 12px;
                color: #3b3b3b;
                padding: 0 0 11px 0;
            }

    #add-licence-modal .add-licence-modal_selectors-section .labeled-input .input {
                width: 350px;
                height: 40px;
                border-radius: 5px;
                padding: 0 8px;
                box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
                border: solid 1px #f7f7f7;
                background-color: #ffffff;
            }

    #add-licence-modal .add-licence-modal_selectors-section .add-licence-modal_field-title {
            font-size: 12px;
            color: #3b3b3b;
            padding-bottom: 11px;
        }

    #add-licence-modal .add-licence-modal_selectors-section .drop-down-container {
            position: relative;
            width: 350px;
            height: 40px;
            background-color: #fff;
            margin-bottom: 24px;
        }

    #add-licence-modal .add-licence-modal_selectors-section .drop-down-container .label-container {
                padding: 12px 9px;
                border-radius: 5px;
            }

    #add-licence-modal .add-licence-modal_selectors-section .drop-down-container .label-container .label{
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 300;
                    color: #808080;
                }

    #add-licence-modal .add-licence-modal_selectors-section .drop-down-container .list-container {
                top: 40px;
                width: 100%;
                padding: 9px 18px;
            }

    #add-licence-modal .add-licence-modal_selectors-section .drop-down-container .list-container .list-item{ 
                    line-height: 20px;
                    margin: 4px 0px;
                    font-size: 12px;
                    font-weight: 300;
                    color: #3b3b3b;
                }

    #add-licence-modal .add-licence-modal_selectors-section .drop-down-container .list-container .list-item:hover{
                        background-color: #eee;
                    }

    #add-licence-modal .add-licence-modal_selectors-section .add-licence-modal_users {
            background: white;
            margin-bottom: 24px;
            width: 350px;
            border-radius: 5px;
        }

    #add-licence-modal .add-licence-modal_selectors-section .add-licence-modal_users #multiselectContainerReact > div {
                    border: none;
                }

    #add-licence-modal .add-licence-modal_selectors-section .add-licence-modal_users #multiselectContainerReact > div input {
                        padding: 5px;
                        color: var(--brown-grey);
                        font-size: 12px;
                        font-weight: 300;
                    }

    #add-licence-modal .add-licence-modal_selectors-section .add-license-modal_users input, #add-licence-modal .add-licence-modal_selectors-section .add-license-modal_date input {
                border-radius: 5px;
                width: 350px;
                padding: 10px;
                color: var(--brown-grey);
                font-size: 12px;
                font-weight: 300;
            }

    #add-licence-modal .button {
        margin-top: 26px;
        align-self: center;
    }
#add-company-modal {
    display: flex;
    flex-direction: column;
    padding: 32px 36px;
}

    #add-company-modal .add-company-modal_title{
        font-size: 24px;
        font-weight: 600;
        color: #3b3b3b;
        margin-bottom: 35px;
    }

    #add-company-modal .add-company-modal_selectors-section {
        padding: 24px 24px 80px 24px;
        background-color: rgba(228, 228, 228, 0.58);
    }

    #add-company-modal .add-company-modal_selectors-section .labeled-input {
            padding: 0 0 24px 0;
        }

    #add-company-modal .add-company-modal_selectors-section .labeled-input .label {
                font-size: 12px;
                color: #3b3b3b;
                padding: 0 0 11px 0;
            }

    #add-company-modal .add-company-modal_selectors-section .labeled-input .input {
                width: 354px;
                height: 40px;
                border-radius: 5px;
                padding: 0 8px;
                box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
                border: solid 1px #f7f7f7;
                background-color: #ffffff;
            }

    #add-company-modal .add-company-modal_selectors-section .add-company-modal_selector-title{
            font-size: 12px;
            color: #3b3b3b;
            padding-bottom: 11px;
        }

    #add-company-modal .add-company-modal_selectors-section .drop-down-container {
            position: relative;
            width: 350px;
            height: 40px;
            background-color: #fff;
            margin-bottom: 24px;
        }

    #add-company-modal .add-company-modal_selectors-section .drop-down-container .label-container {
                padding: 12px 9px;
                border-radius: 5px;
            }

    #add-company-modal .add-company-modal_selectors-section .drop-down-container .label-container .label{
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 300;
                    color: #808080;
                }

    #add-company-modal .add-company-modal_selectors-section .drop-down-container .list-container {
                top: 40px;
                width: 100%;
                padding: 9px 18px;
            }

    #add-company-modal .add-company-modal_selectors-section .drop-down-container .list-container .list-item{ 
                    line-height: 20px;
                    margin: 4px 0px;
                    font-size: 12px;
                    font-weight: 300;
                    color: #3b3b3b;
                }

    #add-company-modal .add-company-modal_selectors-section .drop-down-container .list-container .list-item:hover{
                        background-color: #eee;
                    }

    #add-company-modal .button {
        margin-top: 26px;
        align-self: center;
    }
#upload-modal {
    
}
.file-uploader {
    border-radius: 6px;
    box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.17);
    padding: 62px 70px;
    text-align: center;
}

    .file-uploader h1 {
        margin: auto;
        font-size: 44px;
        font-weight: 600;
        line-height: 1;
        color: var(--cobalt);
    }

    .file-uploader .dnd-section {
        max-width: 540px;
        height: 82px;
        margin: auto;
        margin-bottom: 60px;
        border: dashed 1px #979797;
        padding: 26px 0px;
        color: var(--black);
    }

    .file-uploader .dnd-section div:focus {
            outline: none;
        }

    .file-uploader .dnd-section.hovering-over {
            border-style: solid;
            border-color: var(--brown-grey-light);
            color: var(--brown-grey-light);
        }

    .file-uploader .dnd-section.file-uploaded {
            background-color: var(--white);
            border: none;
        }

    .file-uploader .dnd-section span {
            text-decoration: underline;
            cursor: pointer;
        }

    .file-uploader .dnd-section p {
            font-size: 20px;
            cursor: pointer;
        }

    .file-uploader .dnd-section p:focus {
                outline: none;
            }

    .file-uploader .dnd-section .selected-file {
            display: flex;
        }

    .file-uploader .dnd-section .file-info {
            font-size: 16px;
            text-align: left;
            flex: 1 1;
        }

    .file-uploader .dnd-section .file-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

    .file-uploader .dnd-section .file-size {
            font-size: 12px;
        }

    .file-uploader .dnd-section .trash-icon {
            padding-right: 28px;
        }

    .file-uploader .dnd-section .Excel-icon {
            padding-left: 26px;
            padding-right: 16px;
        }

    .file-uploader .dnd-section .Excel-icon svg {
                width: 27px;
                height: 34px;
            }

    .file-uploader .upload-description {
        font-size: 18px;
        font-weight: 300;
        line-height: 1.56;
        height: 98px;
        margin: 24px auto 0px auto;
        text-align: center;
        color: var(--black);
    }

    .file-uploader .button.upload-Excel {
        width: 169px;
        height: 44px;
        border-radius: 30px;
        box-shadow: 0 4px 10px 0 rgba(35, 64, 143, 0.44);
        background-color: var(--cobalt);
        
        font-size: 18px;
        color: #ffffff;
    }

    .file-uploader .button.upload-Excel[disabled] {
            background-color: var(--very-light-pink);
            box-shadow: none;
        }
#modal-container {
    min-height: auto;
}
#edit-user-modal {
    display: flex;
    flex-direction: column;
    padding: 32px 36px;
}
#edit-user-modal .edit-user-modal_title{
        font-size: 24px;
        font-weight: 600;
        color: #3b3b3b;
        margin-bottom: 35px;
    }
#edit-user-modal .edit-user-modal_selectors-section {
        padding: 24px 24px 2px 24px;
        background-color: rgba(228, 228, 228, 0.58);
    }
#edit-user-modal .edit-user-modal_selectors-section .edit-user-modal_selector-title{
            font-size: 12px;
            color: #3b3b3b;
            padding-bottom: 11px;
        }
#edit-user-modal .edit-user-modal_selectors-section .drop-down-container {
            position: relative;
            width: 350px;
            height: 40px;
            background-color: #fff;
            margin-bottom: 24px;
        }
#edit-user-modal .edit-user-modal_selectors-section .drop-down-container .label-container {
                padding: 12px 9px;
                border-radius: 5px;
            }
#edit-user-modal .edit-user-modal_selectors-section .drop-down-container .label-container .label{
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 300;
                    color: #808080;
                }
#edit-user-modal .edit-user-modal_selectors-section .drop-down-container .list-container {
                top: 40px;
                width: 100%;
                padding: 9px 18px;
            }
#edit-user-modal .edit-user-modal_selectors-section .drop-down-container .list-container .list-item{ 
                    line-height: 20px;
                    margin: 4px 0px;
                    font-size: 12px;
                    font-weight: 300;
                    color: #3b3b3b;
                }
#edit-user-modal .edit-user-modal_selectors-section .drop-down-container .list-container .list-item:hover{
                        background-color: #eee;
                    }
#edit-user-modal .button {
        margin-top: 26px;
        align-self: center;
    }
#add-licence-modal {
    display: flex;
    flex-direction: column;
    padding: 32px 36px;
}

    #add-licence-modal .add-licence-modal_title {
        font-size: 24px;
        font-weight: 600;
        color: #3b3b3b;
        margin-bottom: 35px;
    }

    #add-licence-modal .add-licence-modal_selectors-section {
        padding: 24px;
        background-color: rgba(228, 228, 228, 0.58);
    }

    #add-licence-modal .add-licence-modal_selectors-section .labeled-input {
            padding: 0 0 24px 0;
        }

    #add-licence-modal .add-licence-modal_selectors-section .labeled-input .label {
                font-size: 12px;
                color: #3b3b3b;
                padding: 0 0 11px 0;
            }

    #add-licence-modal .add-licence-modal_selectors-section .labeled-input .input {
                width: 350px;
                height: 40px;
                border-radius: 5px;
                padding: 0 8px;
                box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
                border: solid 1px #f7f7f7;
                background-color: #ffffff;
            }

    #add-licence-modal .add-licence-modal_selectors-section .add-licence-modal_field-title {
            font-size: 12px;
            color: #3b3b3b;
            padding-bottom: 11px;
        }

    #add-licence-modal .add-licence-modal_selectors-section .drop-down-container {
            position: relative;
            width: 350px;
            height: 40px;
            background-color: #fff;
            margin-bottom: 24px;
        }

    #add-licence-modal .add-licence-modal_selectors-section .drop-down-container .label-container {
                padding: 12px 9px;
                border-radius: 5px;
            }

    #add-licence-modal .add-licence-modal_selectors-section .drop-down-container .label-container .label{
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 300;
                    color: #808080;
                }

    #add-licence-modal .add-licence-modal_selectors-section .drop-down-container .list-container {
                top: 40px;
                width: 100%;
                padding: 9px 18px;
            }

    #add-licence-modal .add-licence-modal_selectors-section .drop-down-container .list-container .list-item{ 
                    line-height: 20px;
                    margin: 4px 0px;
                    font-size: 12px;
                    font-weight: 300;
                    color: #3b3b3b;
                }

    #add-licence-modal .add-licence-modal_selectors-section .drop-down-container .list-container .list-item:hover{
                        background-color: #eee;
                    }

    #add-licence-modal .add-licence-modal_selectors-section .add-licence-modal_users {
            background: white;
            margin-bottom: 24px;
            width: 350px;
            border-radius: 5px;
        }

    #add-licence-modal .add-licence-modal_selectors-section .add-licence-modal_users #multiselectContainerReact > div {
                    border: none;
                }

    #add-licence-modal .add-licence-modal_selectors-section .add-licence-modal_users #multiselectContainerReact > div input {
                        padding: 5px;
                        color: var(--brown-grey);
                        font-size: 12px;
                        font-weight: 300;
                    }

    #add-licence-modal .add-licence-modal_selectors-section .add-license-modal_users input, #add-licence-modal .add-licence-modal_selectors-section .add-license-modal_date input {
                border-radius: 5px;
                width: 350px;
                padding: 10px;
                color: var(--brown-grey);
                font-size: 12px;
                font-weight: 300;
            }

    #add-licence-modal .button {
        margin-top: 26px;
        align-self: center;
    }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0; /* 1 */
    padding: 0;
    white-space: normal; /* 2 */
    *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */ /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

img {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
html, body {
	height: 100%;
}

#root {
	height: 100%;
	overflow-x: hidden;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #09d3ac;
}

.company-logo img {
		position: absolute;
		/* top: 35px;
		left: 24px;
		filter: brightness(0) invert(1); */
	}
#help-page {
    position: relative;
}

    #help-page .help-page-header {
        display: flex;
        position: relative;
        justify-content: space-between;
    }

    #help-page .logo-background {
        background-color: transparent;
    }

    #help-page .help-page-container .filter-wrapper {
            width: 100%;
            height: 386px;
            box-shadow: inset -15px 1px 29px 0 rgba(27, 53, 128, 0.77);
            background: linear-gradient(48deg, rgba(0,23,104,1) 0%, rgba(35,64,143,1) 100%);
            margin-top: -125px;
            margin-bottom: 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

    #help-page .help-page-container .filter-wrapper h2 {
                font-size: 42px;
                font-weight: 600;
                text-align: center;
                margin-bottom: 36px;
                color: white;
            }

    #help-page .help-page-container .filter-wrapper input {
                width: 480px;
            }

    #help-page .help-page-container .help-page-sections {
            width: 80%;
            max-width: 1100px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin: auto;
        }

    #help-page .help-page-container .help-page-section {
            width: 30%;
            max-width: 306px;
            height: 162px;
            border-radius: 11px;
            box-shadow: 0 1px 6px 0 rgba(205, 202, 202, 0.5);
            color: var(--black);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 24px 34px;
            margin-bottom: 38px;
            text-align: center;
            cursor: pointer;
        }

    #help-page .help-page-container .help-page-section:hover {
                box-shadow: 0 1px 15px 0 rgba(205, 202, 202, 0.5);
            }

    #help-page .help-page-container .help-page-section .app-icon {
                margin-bottom: 11px;
            }

    #help-page .help-page-container .help-page-section h2 {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 10px;
            }

    #help-page .help-page-container .help-page-section p {
                font-size: 12px;
                font-weight: 300;
                line-height: 1.5;
            }
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}

p {
    margin: 0;
}

* {
    font-family: Poppins, Montserrat;
    box-sizing: border-box;
}

button {
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

input, button{
    border: none;
    outline: none;
}

input:active, input:focus, button:active, button:focus {
        outline: none;
        border: none;
    }

:root {
    --cobalt: #23408f;
    --light-blue-grey: #c8cfe3;
    --bluey-grey: #7c8dbc;
    --blueberry: #4d339f;
    /* --pale-lilac: #dedbf0; */
    --brown-grey: #808080;
    --brown-grey-light: #b1b1b1;
    --very-light-pink: #e4e4e4;
    --white: #f7f7f7;
    --black: #3b3b3b;
    --dark-seafoam-green: #40b881;
    --pastel-red: #ef4744;
    --marigold: #fbc200;
    --pale-lilac: #e9e8f0;
    --lavender: #bfbad2;
    --wisteria: #958cb4;
    --greyish-purple: #76699e;
}

.hoverable {
    transition: opacity .3s ease;
}

.hoverable:hover {
        opacity: .8;
    }

.Toastify__progress-bar {
    visibility: hidden;
}

.label-item-popover, .actor-item-popover, .scenario-item-popover {
    width: 500px;
    background-color: #f7f7f7;
    background-color: var(--white);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
    padding: 18px;

    font-size: 12px;
    font-weight: 300;
    line-height: 1.67;
    color: #3b3b3b;
    color: var(--black);
}

.label-item-popover .label-item-state, .actor-item-popover .label-item-state, .scenario-item-popover .label-item-state {
        display: flex;
        align-items: center;
    }

.label-item-popover .label-full-name, .label-item-popover .label-item-state-name, .actor-item-popover .label-full-name, .actor-item-popover .label-item-state-name, .scenario-item-popover .label-full-name, .scenario-item-popover .label-item-state-name {
        font-weight: 400;
    }

.label-item-popover .label-full-name, .actor-item-popover .label-full-name, .scenario-item-popover .label-full-name {
        line-height: 35px;
    }

.scenario-item-popover {

}

.actor-item-popover {
    width: 200px;
}

.logo-background {
    min-width: 245px;
    height: 125px;
    background-color: #23408f;
    background-color: var(--cobalt);
}

.logo-background .company-logo {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

.rc-tooltip {
    opacity: 1;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
    border-bottom-color: #f7f7f7;
    border-bottom-color: var(--white);
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
    border-top-color: #f7f7f7;
    border-top-color: var(--white);
}

.rc-tooltip-inner {
    border: none;
    background: none;
    padding: 0;
}

.appear-enter, .appear-leave {
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}

.appear-enter.appear-enter-active {
    -webkit-animation-name: appear;
            animation-name: appear;
}

.appear-leave.appear-leave-active {
    -webkit-animation-name: disappear;
            animation-name: disappear;
}

@-webkit-keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes disappear {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes disappear {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
