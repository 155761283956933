#sign-in-form-container{
    width: 500px;
    flex-direction: column;
    /*padding-top: 156px;*/

    .title {
        margin: 0 auto;
        font-family: Poppins, Montserrat;
        font-size: 44px;
        font-weight: 600;
        color: var(--cobalt);
        margin-bottom: 15px;
    }

    .forget-password-label{
        line-height: 16px;
        font-size: 12px;
        line-height: 1.33;
        color: var(--brown-grey);
        display: flex;
        justify-content: flex-end;
        margin: 12px 0px 44px 0px;
        user-select: none;
        cursor: pointer;
    }

    .bot-container {
        display: flex;
        flex-direction: column;
        align-items: left
    }

    .or-label{
        margin: 20px 0px 18px;
    }

    .link {
        line-height: 21px;
        font-size: 18px;
        color: var(--cobalt);
    }

    .sign-in {

        &[disabled] {
            background-color: var(--very-light-pink);
            box-shadow: none;
        }
    }
}
