html, body {
	height: 100%;
}

#root {
	height: 100%;
	overflow-x: hidden;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #09d3ac;
}

.company-logo {
	img {
		position: absolute;
		/* top: 35px;
		left: 24px;
		filter: brightness(0) invert(1); */
	}
}